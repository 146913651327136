<template>
  <div>
    <!-- Modal Absensi -->
    <loading v-if="Loading" />
    <!-- Tabel -->
    <b-row v-if="!Loading">
      <!-- Filter Tanggal -->
      <b-col md="3">
        <b-form-group label="Tanggal">
          <validation-provider #default="{ errors }" name="tanggal" rules="required">
            <flat-pickr @input="submitFilter" v-model="tanggal" :config="config" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid'" placeholder="Date Inserted" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <!-- Filter Nama -->
      <b-col md="3">
        <b-form-group label="Divisi">
          <validation-provider #default="{ errors }" name="Name" rules="required">
            <multiselect @input="submitFilter" v-model="divisi" :options="divisiPolisi" :multiple="false" :close-on-select="true" :clear-on-select="true" placeholder="Pilih USER" label="text" track-by="value" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label=".">
          <b-button variant="success" @click="exportAbsen" v-if="validExport">Export Excel</b-button>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios,
    flatPickr
  },
  data() {
    return {
      Loading: false,
      LoadingDetail: false,
      LoadingDetail2: false,
      tanggal: null,
      divisi: null,
      totalParfum: null,
      divisiPolisi: [],
      perPage: 10, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      perPageDetail: 10, // Jumlah item per halaman
      currentPageDetail: 1, // Halaman saat ini
      periode: null, // Halaman saat ini
      totalOn: null, // Halaman saat ini
      absenFields: [
        {key:"on_duty",text:"On Duty"},
        {key:"off_duty",text:"Off Duty"},
        {key:"total_waktu",text:"Total Waktu"}
      ],
      rekapFields: [
        {key:"tanggal_kerja",text:"Tanggal Kerja"},
        {key:"total_on_duty",text:"Total On Duty"},
        {key:"parfum",text:"Parfum"},
        {key:"action",text:"Action"}
      ],
      config: {
        mode: "range",
        altInput: true,
        dateFormat: "Y-m-d",
        altFormat: "d M Y",
        allowInput: true,
      },
      dataUser: [],
      listAbsen: [],
      listRekap: [],
      validExport: false,
    };
  },
  methods: {
    init(){
      this.getDivisiPolisi();
      this.checkValidAccessButton()
    },
    checkValidAccessButton(){
      axios.post("idp/master/role-config/auth",{id:6}).then((response) => {
        this.validExport = response.data.valid
      })
    },
    getDivisiPolisi(){
      this.Loading = true;
      axios.get("idp_dropdown/divisi-polisi").then((response) => {
        this.divisiPolisi = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
      });
    },
    submitFilter() {
      // if(this.divisi != '' && this.tanggal != null){
      //   this.LoadingDetail = true;
      //   axios.post("idp/attendance/list",{identifier:this.identifier.value,tanggal:this.tanggal.split(" to ")}).then((response) => {
      //     this.totalParfum = response.data.parfum
      //     this.listRekap = response.data.rekap
      //     this.dataUser = response.data.user
      //     this.periode = response.data.periode
      //     this.totalOn = response.data.total_on
      //   }).catch((error) => {
      //     if (error.status === 4000) {
      //       localStorage.clear();
      //       this.$router.push({ path: "/login" });
      //       ({
      //         title: "Login Expired",
      //         message: "Sesi Login Habis",
      //       });
      //     }
      //   }).finally(() => {
      //     this.LoadingDetail = false;
      //     this.listAbsen = []
      //   });
      // }
    },
    viewDetail(row){
      this.LoadingDetail2 = true
      axios.post("idp/attendance/detail",{identifier:row.identifier,tanggal:row.tanggal}).then((response) => {
        this.listAbsen = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.LoadingDetail2 = false;
      });
    },
    exportAbsen: function(){
      if (confirm("Are You Sure?")) {
        this.Loading = true;
        const split = this.tanggal.split("to").map(date => date.trim());
        let param = { from: split[0],to:split[1],divisi:this.divisi };
        this.loading = true;

        axios({
          url: "idp/export/rekap-absensi",
          data: param,
          method: "POST",
          responseType: "blob", //before "blob"
        })
          .then((response) => {
            // var fileDownload = require("js-file-download");
            // fileDownload(response.data, "rekap-absen-polisi.xlsx");

            if(response.status == 200){
              this.$swal({
                title: "Check Inbox",
                icon: "success",
                background: "black",
                confirmButtonColor: "#7367f0",
                confirmButtonText: "Close",
              });
            }else{
              this.$swal({
                title: "Gagal kirim email",
                icon: "warning",
                background: "black",
                confirmButtonColor: "#7367f0",
                confirmButtonText: "Close",
              });
            }
          })
          .catch((error) => {
            this.expired(error, "Error");
          })
          .finally(() => {
            this.Loading = false;
          });
      }
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>